<template>
    <div class="email">
        <div class="popup">
            <div class="true"><img src="../assets/icons/true.png" alt=""></div>
            <div class="title">Cette Page se ne trouve pas</div>
            <div class="back">
                <router-link to="/" exact>Renvoyer le formulaire</router-link>. 
                vous avez un problème? <a href="https://victory-partners.ma/contactez-nous/">Contactez-nous</a>. 
            </div>
        </div>
    </div>
</template>

<script>
window.onbeforeunload = null;
export default {
    name:'NotFound',
    beforeMount() {
        this.$router.push('/')
    },
}
</script>


<style>
#nav{
    display: flex;
  }
  @media screen and (max-width: 768px) {
      #nav{text-align: center;display: block;}
      #nav ul li{float: none;}
      #nav ul{padding-right: 0;}
      #logo{
        padding: 0;
        width: 100%;
      }
      #logo img, #logo span{    
        margin: auto;
      }
    }
.email{
    height: calc(100vh - 58px);
    background-image: linear-gradient(to right, #4e4e4e , #bfbbbb );
    display: flex;
    align-items: center;
}
.popup{
    margin: auto;
    background-color: #ffffff8c;
    padding: 0 40px;
}
.popup div{
    width: 100%;
    margin: 20px auto;
    max-width: 300px;
    overflow-wrap: break-word;
}
.popup .true{
    text-align: center;
}
.popup .true img{
    width: 100px;
}
.popup .title{
    text-align: center;
    color: #2ba25e;
    font-weight: bold;
    font-size: 20px;
}
.popup .subtitle{
    text-align: center;
    font-size: 17px;
}
.popup .cnt{
    text-align: center;
    margin-bottom: 50px;
}
.popup .back {
    text-align: center;
    font-size: 12px;
    margin-bottom: 5px;
    color:#898181;
}
.popup a{
    text-decoration: underline;
    color: blue;
}
    
</style>